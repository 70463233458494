import React from "react"

import Layout from "../components/layout"
import Header from "../components/header"
import SEO from "../components/seo"
import MailchimpSubscribe from "react-mailchimp-subscribe"


class CustomForm extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props)
    this.state = {
      email: null,
      firstname: null,
      lastname: null
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit(event) {
    this.state.email &&
    this.state.firstname &&
    this.state.lastname &&
    this.state.email.indexOf("@") > -1 &&
    this.props.onValidated({
        EMAIL: this.state.email,
        FNAME: this.state.firstname,
        LNAME: this.state.lastname
      })
  }

  render() {
    return (
      <form id="subscribe-form" className="container" onSubmit={this.handleSubmit.bind(this)}>
        {this.props.status === "sending" && <div style={{ marginTop: "1em" }} className="fs-large lh-text">Processing…</div>}
        {this.props.status === "error" && (
          <div
            style={{ marginTop: "1em" }}
            className="fs-medium mb1"
            dangerouslySetInnerHTML={{ __html: this.props.message }}
          />
        )}
        {this.props.status === "success" && (
          <div
            style={{ marginTop: "1em" }}
            className="fs-large lh-text"
            dangerouslySetInnerHTML={{ __html: this.props.message }}
          />
        )}
        {this.props.status !== "success" && this.props.status !== "sending" && (
          <>
            <input
              style={{ marginTop: "1em" }}
              onChange={this.handleChange}
              className="fs-large lh-text"
              type="text"
              placeholder="Firstname"
              name="firstname"
              autoComplete="given-name"
              required
            />
            <br />
            <input
              onChange={this.handleChange}
              className="fs-large lh-text"
              type="text"
              placeholder="Lastname"
              name="lastname"
              autoComplete="family-name"
              required
            />
            <br />
            <input
              onChange={this.handleChange}
              className="fs-large lh-text"
              type="email"
              placeholder="E-mail"
              name="email"
              autoComplete="email"
              required
            />
            <br />
            <button className="ff-sansserif button with-padding uppercase mt1" type="submit" onClick={this.handleSubmit.bind(this)}>
              Submit
            </button>
          </>
        )}
      </form>
    )
  }
};

const IndexPage = () => (
  <Layout>
    <SEO title="Subscription" />
    <Header siteTitle="Online subscriptions are now open." />
    <MailchimpSubscribe
      url={"//marineserre.us17.list-manage.com/subscribe/post?u=fd0148d10b58112407a349ef3&id=2c6ee027c1"}
      render={({ subscribe, status, message }) => (
        <CustomForm
          status={status}
          message={message}
          onValidated={formData => subscribe(formData)}
        />
      )}
    />
  </Layout>
)

export default IndexPage
